var uniqueClasses = function uniqueClasses(list) {
  list = list.split(' ');
  var hash = {};
  var i = list.length;
  var out = [];

  while (i--) {
    if (!hash.hasOwnProperty(list[i])) {
      hash[list[i]] = 1;
      out.unshift(list[i]);
    }
  }

  return out.join(' ');
};

export default uniqueClasses;