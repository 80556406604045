import injectElement from './inject-element.js';
/**
 * :NOTE: We are using get/setAttribute with SVG because the SVG DOM spec
 * differs from HTML DOM and can return other unexpected object types when
 * trying to directly access svg properties. ex: "className" returns a
 * SVGAnimatedString with the class value found in the "baseVal" property,
 * instead of simple string like with HTML Elements.
 */

var SVGInjector = function SVGInjector(elements, _temp, done) {
  var _ref = _temp === void 0 ? {} : _temp,
      _ref$evalScripts = _ref.evalScripts,
      evalScripts = _ref$evalScripts === void 0 ? 'always' : _ref$evalScripts,
      _ref$pngFallback = _ref.pngFallback,
      pngFallback = _ref$pngFallback === void 0 ? false : _ref$pngFallback,
      _ref$each = _ref.each,
      eachCallback = _ref$each === void 0 ? function () {
    return undefined;
  } : _ref$each,
      _ref$renumerateIRIEle = _ref.renumerateIRIElements,
      renumerateIRIElements = _ref$renumerateIRIEle === void 0 ? true : _ref$renumerateIRIEle;

  if (done === void 0) {
    done = function done() {
      return undefined;
    };
  }

  if (elements.length !== undefined) {
    var elementsLoaded = 0;
    Array.prototype.forEach.call(elements, function (element) {
      injectElement(element, evalScripts, pngFallback, renumerateIRIElements, function (error, svg) {
        eachCallback(error, svg);

        if (elements.length === ++elementsLoaded) {
          done(elementsLoaded);
        }
      });
    });
  } else {
    if (elements) {
      injectElement(elements, evalScripts, pngFallback, renumerateIRIElements, function (error, svg) {
        eachCallback(error, svg);
        done(1);
        elements = null;
      });
    } else {
      done(0);
    }
  }
};

export default SVGInjector;