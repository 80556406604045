"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
// what is `import x = require('x');`? great question: https://github.com/Microsoft/TypeScript/issues/5073
var unescape = require("lodash.unescape");
var URL = require("url-parse");
// finds an img tag with HTML attributes
var imgTagRegex = /<img([^>]+)\/>/i;
// finds all the HTML attributes in a string
var htmlAttrsRegex = /([^=\s]+)(="([^"]*)")?/gi;
// finds the Sitecore media URL prefix
var mediaUrlPrefixRegex = /\/([-~]{1})\/media\//i;
/**
 * Makes a request to Sitecore Content Service for the specified item path.
 */
exports.findEditorImageTag = function (editorMarkup) {
    // match the tag
    var tagMatch = editorMarkup.match(imgTagRegex);
    if (!tagMatch || tagMatch.length < 2) {
        return null;
    }
    // find the attrs and turn them into a Map
    var attrs = {};
    var match = htmlAttrsRegex.exec(tagMatch[1]);
    while (match != null) {
        attrs[match[1]] = unescape(match[3]);
        match = htmlAttrsRegex.exec(tagMatch[1]);
    }
    return {
        imgTag: tagMatch[0],
        attrs: attrs,
    };
};
/**
 * Receives a Sitecore media URL and replaces `/~/media` or `/-/media` with `/~/jssmedia` or `/-/jssmedia`, respectively.
 * This replacement allows the JSS media handler to be used for JSS app assets.
 * Also, any provided `params` are used as the querystring parameters for the media URL.
 */
exports.updateImageUrl = function (url, params) {
    // polyfill node `global` in browser to workaround https://github.com/unshiftio/url-parse/issues/150
    if (typeof window !== 'undefined' && !window.global) {
        window.global = {};
    }
    var parsed = URL(url, {}, true);
    parsed.set('query', __assign(__assign({}, parsed.query), params));
    var match = mediaUrlPrefixRegex.exec(parsed.pathname);
    if (match && match.length > 1) {
        // regex will provide us with /-/ or /~/ type
        parsed.set('pathname', parsed.pathname.replace(mediaUrlPrefixRegex, "/" + match[1] + "/jssmedia/"));
    }
    return parsed.toString();
};
/**
 * Receives an array of `srcSet` parameters that are iterated and used as parameters to generate
 * a corresponding set of updated Sitecore media URLs via @see updateImageUrl. The result is a comma-delimited
 * list of media URLs with respective dimension parameters.
 *
 * @example
 * // returns '/ipsum.jpg?h=1000&w=1000 1000w, /ipsum.jpg?mh=250&mw=250 250w'
 * getSrcSet('/ipsum.jpg', [{ h: 1000, w: 1000 }, { mh: 250, mw: 250 } ])
 *
 * More information about `srcSet`: {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img}
 */
exports.getSrcSet = function (url, srcSet, imageParams) {
    return srcSet
        .map(function (params) {
        var newParams = __assign(__assign({}, imageParams), params);
        var imageWidth = newParams.w || newParams.mw;
        if (!imageWidth) {
            return null;
        }
        return exports.updateImageUrl(url, newParams) + " " + imageWidth + "w";
    })
        .filter(function (value) { return value; })
        .join(', ');
};
