"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function getFieldValue(renderingOrFields, fieldName, defaultValue) {
    if (!renderingOrFields || !fieldName) {
        return defaultValue;
    }
    var fields = renderingOrFields;
    var field = fields[fieldName];
    if (field && typeof field.value !== 'undefined') {
        return field.value;
    }
    var rendering = renderingOrFields;
    if (!rendering.fields ||
        !rendering.fields[fieldName] ||
        typeof rendering.fields[fieldName].value === 'undefined') {
        return defaultValue;
    }
    return rendering.fields[fieldName].value;
}
exports.getFieldValue = getFieldValue;
/**
 * Gets rendering definitions in a given child placeholder under a current rendering.
 */
function getChildPlaceholder(rendering, placeholderName) {
    if (!rendering ||
        !placeholderName ||
        !rendering.placeholders ||
        !rendering.placeholders[placeholderName]) {
        return [];
    }
    return rendering.placeholders[placeholderName];
}
exports.getChildPlaceholder = getChildPlaceholder;
