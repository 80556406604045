'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _iframeResizer = require('iframe-resizer');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 *
 * inject script to facilitate iframe resizing
 * https://github.com/davidjbradshaw/iframe-resizer
 *
 */
var IframeResizer = function (_React$Component) {
  (0, _inherits3.default)(IframeResizer, _React$Component);

  function IframeResizer() {
    var _ref;

    var _temp, _this, _ret;

    (0, _classCallCheck3.default)(this, IframeResizer);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = (0, _possibleConstructorReturn3.default)(this, (_ref = IframeResizer.__proto__ || (0, _getPrototypeOf2.default)(IframeResizer)).call.apply(_ref, [this].concat(args))), _this), _this.updateIframe = function (props) {
      // has src - no injected content
      if (props.src) return;
      // do we have content to inject (content or children)
      var content = props.content || props.children;
      if (!content) return;
      // get frame to inject into
      var frame = _this.refs.frame;
      if (!frame) return;
      // verify frame document access
      // Due to browser security, this will fail with the following error
      //   Uncaught DOMException: Failed to read the 'contentDocument' property from 'HTMLIFrameElement':
      //   Blocked a frame with origin "http://<hostname>" from accessing a cross-origin frame.
      // resolve this by loading documents from the same domain name,
      // or injecting HTML `content` vs. loading via `src`
      var doc = frame.contentDocument;
      if (!doc) return;
      // replace iframe document content
      if (typeof content === 'string') {
        // assume this is a HTML block
        //   we could send this in via REACT dangerously set HTML
        //   but we are in an iframe anyway, already a red-headed step-child.
        doc.open();
        doc.write(content);
        doc.close();
      } else {
        // assume this is a REACT component
        doc.open();
        doc.write('<div id="iframe-root"></div>');
        doc.close();
        _reactDom2.default.render(content, doc.getElementById('iframe-root'));
      }
    }, _this.injectIframeResizerUrl = function () {
      if (!_this.props.iframeResizerUrl) return;
      var frame = _this.refs.frame;
      if (!frame) return;
      // verify frame document access
      // Due to browser security, this will fail with the following error
      //   Uncaught DOMException: Failed to read the 'contentDocument' property from 'HTMLIFrameElement':
      //   Blocked a frame with origin "http://<hostname>" from accessing a cross-origin frame.
      // resolve this by loading documents from the same domain name,
      // or injecting HTML `content` vs. loading via `src`
      var doc = frame.contentDocument;
      if (!doc) return;
      // where can we insert into? (fail into whatever we can find)
      var injectTarget = null;
      ['head', 'HEAD', 'body', 'BODY', 'div', 'DIV'].forEach(function (tagName) {
        if (injectTarget) return;
        var found = doc.getElementsByTagName(tagName);
        if (!(found && found.length)) return;
        injectTarget = found[0];
      });
      if (!injectTarget) {
        console.error('Unable to inject iframe resizer script');
        return;
      }
      var resizerScriptElement = document.createElement('script');
      resizerScriptElement.type = 'text/javascript';
      resizerScriptElement.src = _this.props.iframeResizerUrl;
      injectTarget.appendChild(resizerScriptElement);
    }, _this.onLoad = function () {
      _this.injectIframeResizerUrl();
      // DISABLED because it's causing a loading loop :(
      // if (this.props.onIframeLoaded) this.props.onIframeLoaded();
    }, _this.resizeIframe = function (props) {
      var frame = _this.refs.frame;
      if (!frame) return;
      if (props.iframeResizerEnable) {
        (0, _iframeResizer.iframeResizer)(props.iframeResizerOptions, frame);
      }
    }, _temp), (0, _possibleConstructorReturn3.default)(_this, _ret);
  }

  (0, _createClass3.default)(IframeResizer, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      // can't update until we have a mounted iframe
      this.updateIframe(this.props);
      this.resizeIframe(this.props);
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      // React will remove the iframe, however we need to manually
      // call iframe-resizer to stop its listeners
      var iframeResizer = this.refs.frame.iFrameResizer;
      iframeResizer && iframeResizer.removeListeners();
    }
  }, {
    key: 'componentWillReceiveProps',
    value: function componentWillReceiveProps(nextProps) {
      // can replace content if we got new props
      this.updateIframe(nextProps);
      this.resizeIframe(nextProps);
    }
    // inject the iframe resizer "content window" script

  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          src = _props.src,
          id = _props.id,
          frameBorder = _props.frameBorder,
          className = _props.className,
          style = _props.style;

      return _react2.default.createElement('iframe', {
        ref: 'frame',
        src: src,
        id: id,
        frameBorder: frameBorder,
        className: className,
        style: style,
        onLoad: this.onLoad
      });
    }
  }]);
  return IframeResizer;
}(_react2.default.Component);

IframeResizer.propTypes = {
  // iframe content/document
  // option 1. content of HTML to load in the iframe
  content: _propTypes2.default.oneOfType([_propTypes2.default.string, _propTypes2.default.element]),
  // option 2. src to a URL to load in the iframe
  src: _propTypes2.default.string,
  // iframe-resizer controls and helpers
  iframeResizerEnable: _propTypes2.default.bool,
  iframeResizerOptions: _propTypes2.default.object,
  iframeResizerUrl: _propTypes2.default.oneOfType([_propTypes2.default.string, // URL to inject
  _propTypes2.default.bool] // false = disable inject
  ),
  // misc props to pass through to iframe
  id: _propTypes2.default.string,
  frameBorder: _propTypes2.default.number,
  className: _propTypes2.default.string,
  style: _propTypes2.default.object
  // optional extra callback when iframe is loaded
  // onIframeLoaded: PropTypes.func,
};
IframeResizer.defaultProps = {
  // resize iframe
  iframeResizerEnable: true,
  iframeResizerOptions: {
    // log: true,
    // autoResize: true,
    // checkOrigin: false,
    // resizeFrom: 'parent',
    // heightCalculationMethod: 'max',
    // initCallback: () => { console.log('ready!'); },
    // resizedCallback: () => { console.log('resized!'); },
  },
  iframeResizerUrl: 'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.5.8/iframeResizer.contentWindow.min.js',
  // misc props to pass through to iframe
  frameBorder: 0,
  style: {
    width: '100%',
    minHeight: 20
  }
};

exports.default = IframeResizer;