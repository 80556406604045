"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var ResponseError = /** @class */ (function (_super) {
    __extends(ResponseError, _super);
    function ResponseError(message, response) {
        var _this = _super.call(this, message) || this;
        Object.setPrototypeOf(_this, ResponseError.prototype);
        _this.response = response;
        return _this;
    }
    return ResponseError;
}(Error));
function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }
    var error = new ResponseError(response.statusText, response);
    throw error;
}
// note: encodeURIComponent is available via browser (window) or natively in node.js
// if you use another js engine for server-side rendering you may not have native encodeURIComponent
// and would then need to install a package for that functionality
function getQueryString(params) {
    return Object.keys(params)
        .map(function (k) { return encodeURIComponent(k) + "=" + encodeURIComponent(params[k]); })
        .join('&');
}
function fetchData(url, fetcher, params) {
    if (params === void 0) { params = {}; }
    var qs = getQueryString(params);
    var fetchUrl = url.indexOf('?') !== -1 ? url + "&" + qs : url + "?" + qs;
    return fetcher(fetchUrl)
        .then(checkStatus)
        .then(function (response) {
        // axios auto-parses JSON responses, don't need to JSON.parse
        return response.data;
    });
}
var resolveLayoutServiceUrl = function (options, verb) {
    if (options === void 0) { options = {}; }
    var _a = options.host, host = _a === void 0 ? '' : _a, _b = options.configurationName, configurationName = _b === void 0 ? 'jss' : _b, serviceUrl = options.serviceUrl;
    if (serviceUrl) {
        return serviceUrl;
    }
    return host + "/sitecore/api/layout/" + verb + "/" + configurationName;
};
/**
 * Makes a request to Sitecore Layout Service for the specified route item path.
 */
function fetchRouteData(itemPath, options) {
    var querystringParams = options.querystringParams, layoutServiceConfig = options.layoutServiceConfig;
    var fetchUrl = resolveLayoutServiceUrl(layoutServiceConfig, 'render');
    return fetchData(fetchUrl, options.fetcher, __assign({ item: itemPath }, querystringParams));
}
exports.fetchRouteData = fetchRouteData;
/**
 * Makes a request to Sitecore Layout Service for the specified placeholder in
 * a specific route item. Allows you to retrieve rendered data for individual placeholders instead of entire routes.
 */
function fetchPlaceholderData(placeholderName, itemPath, options) {
    var querystringParams = options.querystringParams, layoutServiceConfig = options.layoutServiceConfig;
    var fetchUrl = resolveLayoutServiceUrl(layoutServiceConfig, 'placeholder');
    return fetchData(fetchUrl, options.fetcher, __assign({ placeholderName: placeholderName, item: itemPath }, querystringParams));
}
exports.fetchPlaceholderData = fetchPlaceholderData;
